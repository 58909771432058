define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/topic-title/topic-custom-field-topic-title", ["exports", "@glimmer/component", "@ember/controller", "@ember/object/computed", "@ember/service", "@glimmer/tracking", "@ember/template"], function (_exports, _component, _controller, _computed, _service, _tracking, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * type:        step
   * number:      8
   * title:       Display your field value in the topic
   * description: Display the value of your custom topic field below the
   *              title in the topic
   *              list.
   */

  class TopicCustomFieldTopicTitle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fabubloxApi", [_service.service]))();
    #fabubloxApi = (() => (dt7948.i(this, "fabubloxApi"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.alias)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "processSvg", [_tracking.tracked], function () {
      return null;
    }))();
    #processSvg = (() => (dt7948.i(this, "processSvg"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "processData", [_tracking.tracked], function () {
      return null;
    }))();
    #processData = (() => (dt7948.i(this, "processData"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    constructor() {
      super(...arguments);
      this.loadProcessData();
    }
    get fieldValue() {
      return this.args.outletArgs.model.get(this.fieldName);
    }
    isValidProcessId(str) {
      // UUID format validation regex
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/i;
      return uuidRegex.test(str);
    }
    get processId() {
      if (!this.fieldValue) return null;

      // If it's already a valid process ID, use it directly
      if (this.isValidProcessId(this.fieldValue)) {
        return this.fieldValue;
      }

      // If it's a full URL, extract and validate the process ID
      const parts = this.fieldValue.split("/");
      const lastPart = parts[parts.length - 1];
      if (this.isValidProcessId(lastPart)) {
        return lastPart;
      }
      return null;
    }
    get fieldUrl() {
      const processId = this.processId;
      if (!processId) return null;
      return `https://www.fabublox.com/process-editor/${processId}`;
    }
    get processName() {
      return this.processData?.processName || "View Process";
    }
    get svgContent() {
      return this.processSvg ? (0, _template.htmlSafe)(this.processSvg) : null;
    }
    async loadProcessData() {
      const processId = this.processId;
      if (!processId) return;
      this.isLoading = true;
      try {
        // Fetch process data
        const processData = await this.fabubloxApi.fetchProcessById(processId);
        this.processData = processData;

        // Fetch SVG preview
        const svg = await this.fabubloxApi.getProcessSvgPreview(processId);
        this.processSvg = svg;
      } catch (error) {
        console.error("Error loading process data:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
  _exports.default = TopicCustomFieldTopicTitle;
});