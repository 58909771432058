define("discourse/plugins/discourse-topic-custom-fields/discourse/components/topic-list-custom-field", ["exports", "@glimmer/component", "@ember/service", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListCustomField extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get fieldName() {
      return this.siteSettings.topic_custom_field_name;
    }
    get fieldValue() {
      return this.args.topic?.[this.fieldName];
    }
    get showCustomField() {
      return !!this.fieldValue;
    }
    isValidProcessId(str) {
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/?$/i;
      return uuidRegex.test(str);
    }
    get fieldUrl() {
      if (!this.fieldValue) return null;
      // If it's already a valid process ID, use it directly
      if (this.isValidProcessId(this.fieldValue)) {
        return `https://www.fabublox.com/process-editor/${this.fieldValue}`;
      }
      // If it's a full URL, extract and validate the process ID
      const parts = this.fieldValue.split("/");
      const lastPart = parts[parts.length - 1];
      if (this.isValidProcessId(lastPart)) {
        return `https://www.fabublox.com/process-editor/${lastPart}`;
      }
      return this.fieldValue; // Return the original value if it doesn't match expected patterns
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showCustomField}}
          <a href={{this.fieldUrl}} target="_blank" class="referenced-button">
            <svg xmlns="http://www.w3.org/2000/svg" id="svg5" 
            viewBox="0 0 346.76 286.46" class="referenced-icon">
              <defs>
                <style>
                  .cls-1 {
                    stroke: #606f7e;
                  }
                  .cls-1, .cls-2 {
                    stroke-width: 22.68px;
                  }
                  .cls-1, .cls-2, .cls-3 {
                    fill: none;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  }
                  .cls-2 {
                    stroke: #000;
                  }
                  .cls-3 {
                    stroke: #764d82;
                    stroke-width: 22.68px;
                  }
                </style>
              </defs>
              <g id="layer1">
                <path id="path6303" class="cls-2" d="M335.42,86.9L173.38,11.34,11.34,86.9l162.04,75.56,162.04-75.56Z"/>
                <path id="path6311" class="cls-1" d="M11.34,124.45l162.04,75.56,162.04-75.56"/>
                <path id="path6313" class="cls-3" d="M11.34,162.01l162.04,75.56,162.04-75.56"/>
                <path id="path6315" class="cls-2" d="M11.34,199.56l162.04,75.56,162.04-75.56"/>
              </g>
            </svg>
          </a>
        {{/if}}
      
    */
    {
      "id": "ItBSEOti",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showCustomField\"]],[[[1,\"      \"],[10,3],[15,6,[30,0,[\"fieldUrl\"]]],[14,\"target\",\"_blank\"],[14,0,\"referenced-button\"],[12],[1,\"\\n        \"],[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,1,\"svg5\"],[14,\"viewBox\",\"0 0 346.76 286.46\"],[14,0,\"referenced-icon\"],[12],[1,\"\\n          \"],[10,\"defs\"],[12],[1,\"\\n            \"],[10,\"style\"],[12],[1,\"\\n              .cls-1 {\\n                stroke: #606f7e;\\n              }\\n              .cls-1, .cls-2 {\\n                stroke-width: 22.68px;\\n              }\\n              .cls-1, .cls-2, .cls-3 {\\n                fill: none;\\n                stroke-linecap: round;\\n                stroke-linejoin: round;\\n              }\\n              .cls-2 {\\n                stroke: #000;\\n              }\\n              .cls-3 {\\n                stroke: #764d82;\\n                stroke-width: 22.68px;\\n              }\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"g\"],[14,1,\"layer1\"],[12],[1,\"\\n            \"],[10,\"path\"],[14,1,\"path6303\"],[14,0,\"cls-2\"],[14,\"d\",\"M335.42,86.9L173.38,11.34,11.34,86.9l162.04,75.56,162.04-75.56Z\"],[12],[13],[1,\"\\n            \"],[10,\"path\"],[14,1,\"path6311\"],[14,0,\"cls-1\"],[14,\"d\",\"M11.34,124.45l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n            \"],[10,\"path\"],[14,1,\"path6313\"],[14,0,\"cls-3\"],[14,\"d\",\"M11.34,162.01l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n            \"],[10,\"path\"],[14,1,\"path6315\"],[14,0,\"cls-2\"],[14,\"d\",\"M11.34,199.56l162.04,75.56,162.04-75.56\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-custom-fields/discourse/components/topic-list-custom-field.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListCustomField;
});