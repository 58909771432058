define("discourse/plugins/discourse-topic-custom-fields/discourse/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title", ["exports", "discourse/plugins/discourse-topic-custom-fields/discourse/components/topic-list-custom-field", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _topicListCustomField, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <TopicListCustomField @topic={{@outletArgs.topic}} />
  
  */
  {
    "id": "7pPvK/Ju",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-custom-fields/discourse/connectors/topic-list-after-title/topic-custom-field-topic-list-after-title.js",
    "scope": () => [_topicListCustomField.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topic-custom-field-topic-list-after-title"));
});