define("discourse/plugins/discourse-topic-custom-fields/discourse/components/topic-custom-field-input", ["exports", "@glimmer/component", "@ember/component", "@ember/modifier", "@ember/object/computed", "@ember/service", "truth-helpers", "discourse/plugins/discourse-topic-custom-fields/discourse/components/process-selector", "@ember/template-factory"], function (_exports, _component, _component2, _modifier, _computed, _service, _truthHelpers, _processSelector, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicCustomFieldInput extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "fieldName", [(0, _computed.readOnly)("siteSettings.topic_custom_field_name")]))();
    #fieldName = (() => (dt7948.i(this, "fieldName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "fieldType", [(0, _computed.readOnly)("siteSettings.topic_custom_field_type")]))();
    #fieldType = (() => (dt7948.i(this, "fieldType"), void 0))();
    get isProcessField() {
      return this.fieldName === "price" || this.fieldName === "process_id" || this.fieldName === "processid" || this.fieldName === "Process URL";
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (eq this.fieldType "boolean")}}
          <Input
            @type="checkbox"
            @checked={{@fieldValue}}
            {{on "change" (action @onChangeField value="target.checked")}}
          />
          <span>{{this.fieldName}}</span>
        {{/if}}
    
        {{#if (eq this.fieldType "integer")}}
          <Input
            @type="number"
            @value={{@fieldValue}}
            placeholder="Enter {{this.fieldName}}"
            class="topic-custom-field-input small"
            {{on "change" (action @onChangeField value="target.value")}}
          />
        {{/if}}
    
        {{#if (eq this.fieldType "string")}}
          {{#if this.isProcessField}}
            <ProcessSelector
              @fieldValue={{@fieldValue}}
              @onChangeField={{@onChangeField}}
            />
          {{else}}
            <Input
              @type="text"
              @value={{@fieldValue}}
              placeholder="Enter {{this.fieldName}}"
              class="topic-custom-field-input large"
              {{on "change" (action @onChangeField value="target.value")}}
            />
          {{/if}}
        {{/if}}
    
        {{#if (eq this.fieldType "json")}}
          <Textarea
            @value={{@fieldValue}}
            {{on "change" (action @onChangeField value="target.value")}}
            placeholder="Enter {{this.fieldName}}"
            class="topic-custom-field-textarea"
          />
        {{/if}}
      
    */
    {
      "id": "k6dndIE6",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"boolean\"],null],[[[1,\"      \"],[8,[32,1],[[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.checked\"]]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1]]],null],[1,\"\\n      \"],[10,1],[12],[1,[30,0,[\"fieldName\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"integer\"],null],[[[1,\"      \"],[8,[32,1],[[16,\"placeholder\",[29,[\"Enter \",[30,0,[\"fieldName\"]]]]],[24,0,\"topic-custom-field-input small\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@type\",\"@value\"],[\"number\",[30,1]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"string\"],null],[[[41,[30,0,[\"isProcessField\"]],[[[1,\"        \"],[8,[32,3],null,[[\"@fieldValue\",\"@onChangeField\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,1],[[16,\"placeholder\",[29,[\"Enter \",[30,0,[\"fieldName\"]]]]],[24,0,\"topic-custom-field-input large\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@type\",\"@value\"],[\"text\",[30,1]]],null],[1,\"\\n\"]],[]]]],[]],null],[1,\"\\n\"],[41,[28,[32,0],[[30,0,[\"fieldType\"]],\"json\"],null],[[[1,\"      \"],[8,[32,4],[[16,\"placeholder\",[29,[\"Enter \",[30,0,[\"fieldName\"]]]]],[24,0,\"topic-custom-field-textarea\"],[4,[32,2],[\"change\",[28,[31,1],[[30,0],[30,2]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\"]],[]],null],[1,\"  \"]],[\"@fieldValue\",\"@onChangeField\"],false,[\"if\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-topic-custom-fields/discourse/components/topic-custom-field-input.js",
      "scope": () => [_truthHelpers.eq, _component2.Input, _modifier.on, _processSelector.default, _component2.Textarea],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicCustomFieldInput;
});